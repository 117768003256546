import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

class IndustriesPartners extends React.Component {
  render() {
    return (
      <div>
        <MDBRow>
          <MDBCol md="12" className="mb-5">
            <p className="text-medium">{this.props.partnerdescription}</p>
          </MDBCol>

          {this.props.partnerimage.map((partners, index) => {
            return (
              <MDBCol md="3" className="text-center mt-3" key={index}>
                <div className="d-flex p-4">
                  <div className="flex-1">
                    <Img fluid={partners.image.childImageSharp.fluid} />
                  </div>
                </div>
              </MDBCol>
            )
          })}
        </MDBRow>

        <MDBRow>
          <MDBCol>
            <Link
              // to={this.props.partnerlink}
              to="/platforms/"
              className="nav-link btn btn-mdb-color btn-sm-block mt-5"
            >
              <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
              {this.props.partnerlinktext}
            </Link>
          </MDBCol>
        </MDBRow>
      </div>
    )
  }
}

export default IndustriesPartners
