import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import Img from 'gatsby-image'

class IndustriesPlatform extends React.Component {
  render() {
    return (
      <>
        <MDBRow className="mt-3">
          <MDBCol md="6 mt-5">
            <Img
              fluid={this.props.platformimage}
              className="image-fluid"
              alt={this.props.platformimagealttext}
            />
          </MDBCol>

          <MDBCol md="6">
            <div
              className="mt-3 font-w-400 text-medium"
              dangerouslySetInnerHTML={{
                __html: this.props.platformdescription,
              }}
            />
          </MDBCol>
        </MDBRow>
      </>
    )
  }
}

export default IndustriesPlatform
